.cards-outer__cards-container {
  @apply flex justify-center;
}

.cards-container__card {
  @apply lg:rounded-lg shadow-lg bg-white max-w-sm m-2 lg:mx-2 mx-auto w-4/12;
}

.card__inner {
  @apply p-0 lg:p-6;
}

.card__inner p {
  @apply m-2 lg:m-5 text-gray-700 text-xs lg:text-base text-center lg:text-left mb-4 leading-3 lg:leading-6;
}
.card__image {
  @apply lg:rounded-t-lg;
}

.card__heading {
  @apply text-gray-900 text-xs lg:text-xl text-center lg:text-left font-medium mx-1 my-2 leading-3;
}

.card_inner-spacer {
  @apply h-mobile md:h-tablet lg:h-desktop xl:h-fullres;
}
