.membership-view__inner {
  @apply container my-8 md:my-24 md:px-6 mx-auto;
}
.membership-view__signup-button {
  @apply text-center inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 
    hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 
    active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out w-full;
}

.membership-view__section {
  @apply mb-5 md:mb-32 text-gray-800;
}
.list-inside {
  @apply w-full md:w-4/12 m-auto;
}
.membership-form {
  @apply w-full md:w-9/12 mb-6 lg:mb-0 m-auto;
}

.subscription-benefit {
  @apply mb-4 flex items-center;
}
.check-mark {
  @apply text-green-600 w-4 h-4 mr-2;
}

.membership-view__subtitle {
  @apply uppercase mb-4 text-sm;
}

.card-container__inner {
  @apply p-0 md:p-6 border-b border-gray-300 text-center;
}
