.card-price {
  @apply m-6;
}

.card-price__value {
  @apply text-2xl;
}

.card-price__unit {
  @apply text-gray-500 text-sm;
}
