.invalid-fields {
  @apply bg-red-200 mx-2 mb-6 p-5 text-red-600 rounded-md border border-red-300;
}
.form-row {
  @apply m-1 w-full;
}
.input-container {
  @apply md:m-1 my-3 md:my-1;
}
.input-container > label {
  @apply text-xs text-gray-400;
}
.input-text {
  @apply
    form-control
    block
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 
    focus:bg-white 
    focus:border-blue-300
    focus:outline-none;
}
