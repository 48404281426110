.nav-top {
  @apply flex mr-3 my-auto;
}

#nav-top__toggle:checked ~ .mobile__links {
  @apply block bg-white absolute mt-3 border-2 border-t-2 right-0 px-10 py-4 z-10 leading-10 w-full text-center;
  transform: translateX(0);
  animation: slidein 300ms;
}
#nav-top__toggle ~ .nav-top__links .nav-top__item {
  @apply md:my-1 my-5;
}
.nav-top__toggle {
  @apply hidden;
}
.nav-top__hamburger {
  @apply flex-1 md:hidden block p-1;
}
.hamburger__line {
  @apply block my-1 border-t w-8 bg-white;
}

.nav-top__desktop > .nav-top__item {
  @apply flex-col block mx-4 lg:mx-5 text-gray-200 lg:text-base text-xs;
  transition: all 0.2s ease;
}

.nav-top__mobile > .mobile__links > .nav-top__item {
  @apply flex-col block p-2 text-orange-500 text-base;
  transition: all 0.2s ease;
}

.nav-top__item:hover {
  @apply text-white;
}

.nav-top__desktop {
  @apply hidden md:flex justify-end;
}

.nav-top__mobile {
  @apply block md:hidden p-1;
}

.mobile__links {
  @apply text-orange-500 md:flex md:relative md:justify-end md:z-10 z-10 hidden;
}

@keyframes slidein {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
