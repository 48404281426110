.quantity-container {
  @apply rounded-md flex h-12 border mx-auto w-11/12 md:w-9/12 lg:w-6/12 sm:w-9/12 overflow-hidden;
}

.quantity-container > button,
.quantity-container > input {
  @apply grow;
}

.quantity-input {
  @apply text-center p-2 w-14 h-12;
}

button.quanity {
  @apply text-white bg-orange-500 px-4 font-bold text-xl h-12;
}

button.quanity.disabled {
  @apply bg-gray-200 cursor-default;
}

button.decrease {
  border-radius: 5px 0px 0px 5px;
}

button.increase {
  border-radius: 0px 5px 5px 0px;
}
