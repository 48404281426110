@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Raleway";
  src: url('./fonts/Raleway/Raleway-VariableFont_wght.ttf') format('truetype'), url('./fonts/Raleway/Raleway-Italic-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 900;
  font-style: normal italic
}

@font-face {
  font-family: "Raleway";
  src: url('./fonts/Raleway/static/Raleway-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal
}

@font-face {
  font-family: "Raleway";
  src: url('./fonts/Raleway/static/Raleway-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic
}

@font-face {
  font-family: "Raleway";
  src: url('./fonts/Raleway/static/Raleway-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal
}

@font-face {
  font-family: "Raleway";
  src: url('./fonts/Raleway/static/Raleway-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic
}

@font-face {
  font-family: "Raleway";
  src: url('./fonts/Raleway/static/Raleway-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal
}

@font-face {
  font-family: "Raleway";
  src: url('./fonts/Raleway/static/Raleway-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic
}

@font-face {
  font-family: "Raleway";
  src: url('./fonts/Raleway/static/Raleway-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal
}

@font-face {
  font-family: "Raleway";
  src: url('./fonts/Raleway/static/Raleway-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic
}

@font-face {
  font-family: "Raleway";
  src: url('./fonts/Raleway/static/Raleway-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal
}

@font-face {
  font-family: "Raleway";
  src: url('./fonts/Raleway/static/Raleway-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic
}

@font-face {
  font-family: "Raleway";
  src: url('./fonts/Raleway/static/Raleway-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal
}

@font-face {
  font-family: "Raleway";
  src: url('./fonts/Raleway/static/Raleway-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic
}

@font-face {
  font-family: "Raleway";
  src: url('./fonts/Raleway/static/Raleway-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal
}

@font-face {
  font-family: "Raleway";
  src: url('./fonts/Raleway/static/Raleway-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic
}

@font-face {
  font-family: "Raleway";
  src: url('./fonts/Raleway/static/Raleway-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal
}

@font-face {
  font-family: "Raleway";
  src: url('./fonts/Raleway/static/Raleway-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic
}

@font-face {
  font-family: "Raleway";
  src: url('./fonts/Raleway/static/Raleway-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal
}

@font-face {
  font-family: "Raleway";
  src: url('./fonts/Raleway/static/Raleway-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic
}

* {
  font-family: "Raleway", Arial;
  font-weight: 400;
}
