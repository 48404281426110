.symbol-trademark {
  color: #fff;
  width: 17px;
  height: 17px;
  line-height: 17px;
  margin: 2px 0 0 5px;
  font-size: 0.35rem;
  font-weight: 500;
  border: 1px solid;
  border-radius: 50%;
  letter-spacing: 1px;
  text-align: center;
  top: 0;
}
