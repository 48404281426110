.schedule-modal-shade {
  @apply fixed top-0 right-0 bottom-0 left-0 z-10;
  transition: all 1s ease;
  background: rgba(0,0,0,0.5);
}
.schedule-modal-container {
  @apply text-black;
}

.form-row {
  @apply m-1 w-full;
}
.input-container {
  @apply m-1;
}
.input-container > label {
  @apply text-xs text-gray-400;
}
.input-text {
  @apply
    form-control
    block
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 
    focus:bg-white 
    focus:border-blue-300
    focus:outline-none;
}
