p {
  @apply m-5;
}
.layout-bg {
  @apply w-full h-full bg-no-repeat bg-cover bg-fixed;
}

#layout-container {
  @apply sm:w-full sm:p-3;
  @apply xl:w-11/12 border-l-2 m-auto p-5 px-5;
}

.section__heading {
  @apply text-base md:text-2xl lg:text-3xl text-center m-1 sm:m-3 md:m-5 font-bold;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
