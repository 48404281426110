.service-buy-card {
  @apply p-2 sm:p-5 md:p-10;
}

.service-buy-card__title {
  @apply text-xl font-bold;
}

.service-buy-card__description {
  @apply w-full md:w-7/12 m-auto my-5;
}
