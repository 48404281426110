@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinner {
  width: 70px;
  height: 70px;
  border: 12px solid #fff; /* Light grey */
  border-top: 12px solid #7c7; /* Black */
  border-radius: 50%;
  animation: spinner 1.2s linear infinite;
}

.spinner-shade {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100vw;
  height: 100vh;
  z-index: 10;
}
